
* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#content {
  /* height: 100%;
  min-height:83vh;*/

  background: white;
  min-height: calc(100vh - 175px);
  border-bottom: 1px solid; 
}

#content.pageNotFound{
  min-height: calc(100vh - 140px);
}

.container
{
  flex-flow: row;
  flex-direction: row;
  display: flex;
  color: white;
  padding: 20px;
  .palVagaiContainer,
  .adikaramListContainer,
  .kurralListContainer {
    display: flex;
    flex-flow: column;

    .palVagaiList,
    .adikaramList {
      background-color: #2c3e50;
      margin: 20px;
    }
    .adikaramList {
      width: 100%;
      .itemData {
        height: 30px;
      }
    }
  }

}